<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>
					Qual dealer
					<strong>deseja {{ type == 'export' ? 'exportar' : 'avaliar' }}?</strong> 
				</span>
			</h4>
		</header>
		<div class="modal-card-body modal-select modal-rounded">
			<b-loading :is-full-page="false" v-model="exporting"></b-loading>
			<ul v-if="!exporting">
				<li v-if="type == 'export'">
					<a class="modal-select__item done" href="#" @click="handleClick(null, null, type)">
						<span class="is-medium">Todos</span>
					</a>
				</li>
				<li v-for="d in dealers" :key="d.id">
					<a class="modal-select__item" :class="{ done: d.status == 'accepted', disabled: d.status == 'require_payment' && user.role.name === 'user' }" href="#" @click="handleClick(d.id, d.status, type)">
						<span>{{ d.name }}</span>
						<b-tooltip v-if="type != 'export'" :label="label(d.status)" type="is-primary" position="is-left">
							<b-icon :icon="icon(d.status)"></b-icon>
						</b-tooltip> 
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'
import { errorToast } from '@/mixins/toast'

export default {
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		dealers: {
			type: [Array],
			required: true
		},
		user: {},
		type: {
			type: String,
			default: 'rate'
		}
	},
	data() {
		return { 
			exporting: false,
			labels: {
				require_payment: 'Aguardando Faturamento',
				waiting: 'Em Avaliação',
				're-evaluated': 'Reavaliado',
				rated: 'Aguardando Conclusão',
				contested: 'Em Contestação',
				//waiting_payment: 'Aguardando Pagamento',
				accepted: 'Concluído'
			},
			icons: {
				require_payment: 'cash-plus',
				waiting: 'clipboard-text-play-outline',
				're-evaluated': 'clipboard-text-multiple-outline',
				rated: 'clipboard-check-outline',
				contested: 'clipboard-text-off-outline',
				//waiting_payment: 'account-cash-outline',
				accepted: 'check'
			}
		}
	},
	computed: {
		...mapGetters('user', [])
	},
	methods: {
		label(labelName) {
			return this.labels[labelName]
		},
		icon(iconName) {
			return this.icons[iconName]
		},
		handleClick(id, status, type) {
			if(type == 'export') {
				this.exportRating(id, status)
			} else {
				this.openRating(id, status)
			}
		},
		openRating(id, status) {
			if ((status === 'waiting' || status === 'require_payment') && this.user.role.name === 'user') {
				errorToast('<strong>Aguardando</strong> avaliação!')
			} else {
				this.$emit('close')
				eventHub.$emit('open-rating', { dealerId: id, ratingId: this.id.toString().replace('#', '') })
			}
		},
		exportRating(id) {
			this.exporting = true
			let ratingId = this.id
			let dealerId = id ? id : 0;

			Api.post(`rating/export-rating`, { ratingId, dealerId }, { responseType: 'blob' })
				.then((res) => {
					let blob = new Blob([res.data], { type: res.data.type })
					let link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'report.xls'
					link.click()
				}).catch((err) => {
					console.log(err)
					errorToast('Ocorreu um <strong>erro</strong> ao exportar as avaliações.')
				}).finally(() => {
					this.exporting = false
					this.$emit('close')
				})
		}
	}
}
</script>
