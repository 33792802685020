<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('ratings', 'edit') || permissionEnabled('ratings', 'delete') || (permissionEnabled('ratings', 'read') && user.permission.slug == 'manager')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :class="l.color ? l.color : 'has-text-grey-light'" v-show="permissionEnabled('ratings', l.type)" @click="handleClick(l, id)">
			<span v-if="l.id == 6 || l.id == 4" class="dropdown-label">{{ section(l.id) }}</span>
			<div>
				<svg-icon :icon="l.icon"></svg-icon>
				<span>{{ nameAction(l.name) }}</span>
			</div>
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 6,
						name: 'Dealers',
						icon: 'profile',
						color: 'has-text-info has-label',
						type: 'read'
					},
					{
						id: 7,
						name: 'Critérios',
						icon: 'criteria',
						color: 'has-text-info',
						type: 'read'
					},
					{
						id: 8,
						name: 'Faturamento',
						icon: 'money',
						color: 'has-text-success',
						type: 'read'
					},
					{
						id: 2,
						name: 'Planilha de Faturamento',
						icon: 'xls',
						color: 'has-text-success',
						type: 'read'
					},
					{
						id: 9,
						name: 'Contestações',
						icon: 'contest',
						type: 'read',
						color: 'has-text-warning'
					},
					{
						id: 4,
						name: 'Exportar',
						icon: 'export',
						type: 'read',
						color: 'has-text-info has-label'
					},
					{
						id: 1,
						name: 'Editar',
						icon: 'edit',
						type: 'edit',
						color: 'has-text-grey-light'
					},
					{
						id: 5,
						name: 'Remover',
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	methods: {
		section(id) {
			return id == 6 ? 'Avaliação' : 'Ações'
		},
		handleClick(item, id) {
			const events = {
				1: 'edit-rating',
				5: 'delete-rating',
				6: 'start-rating',
				7: 'rate-categories',
				8: 'billing-rating',
				9: 'list-contests',
				4: 'export-rating',
				2: 'billing-worksheet'
			}

			eventHub.$emit(events[item.id], { id })
		},
		nameAction(name) {
			if (name == 'Avaliação' && this.user.permission.slug == 'user') {
				return 'Aceitar'
			}

			return name
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user'])
	}
}
</script>
